.modifyData {

    .header {
        background-color: white;

        .grid {
            background-color: var(--back-color);
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }
    }


    .row {
        text-align: center;
    }

}

