@import "../../utils/variables";

.textBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  margin: 1rem;

  p {
    margin: auto 0;
  }
}

@media (min-width: $breakpoint-1 ) {
  .textBoxContainer {
    flex-direction: row;
  }
}