.navbarContainer > button {
    margin: 2px 10px;
    color: white;

}

.navbarContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
}