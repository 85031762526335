@import "../../utils/variables";

.dailyView .barChart {
  font-size: 0.9rem;
  padding: 0px;
  width:100%;
  flex: 2 0 20vh;
  height: 20vh;
}

.dailyView {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
}

@media (min-width: $max-mobile-width) {
  .dailyView {
    height: 40vh;
  }
}