.editContainer {
    margin-left: 1rem;
    margin-right: 1rem;
    
    h2 {
       
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    p {
        margin: auto 0;
    }

} 