@import "../../utils/variables";

$day-wh: clamp(2rem, 7vw, 5rem);

$day-wh-sm: 2.0rem;

.container {
    display: grid;
    grid-template-columns: repeat(7,$day-wh);
    margin: 2rem;
    gap: 0;
    justify-content: center;
}

.emptyDay,
.MviewDay {
    border:1px solid rgba(40, 82, 56,0.0);
    width:$day-wh;
    height:$day-wh;
    display: block;
    color:blue;
    font-size: 5px;
}

// @media (max-width: $max-mobile-width ) {

//     .emptyDay,
//     .MviewDay {
//         width:$day-wh-sm;
//         height:$day-wh-sm;
//     }

//     .container {
//         grid-template-columns: repeat(7,$day-wh-sm);
//     }
    
// }

.emptyDay {
    background-color: white;
}

.color-scale-1 {
    background-color: var(--back-color);
    opacity:0;
}

.color-scale-2 {
    background-color: var(--back-color);
    opacity: 0.33
}

.color-scale-3 {
    background-color: var(--back-color);
    opacity: 0.66;
}

.color-scale-4 {
    background-color: var(--back-color);
}
