@import "../../utils/variables";

.chartandOptions {
    max-width: $max-content-width;
    margin: 1rem;
}

.options {
  grid-area: options;
  margin-bottom: 2rem;
}

.chart {
  text-align: center;
  grid-area: chart;
  
}

.css-qfz70r-MuiFormGroup-root {
  justify-content: center;
}

.habitSelector {
  margin: 1rem;
}

@media (min-width: $breakpoint-1) {

  .chartandOptions {
    display: grid;
    
    grid-template-columns: 15% 85%;
    grid-template-areas: "options chart";
  }
  
}





