@import "./utils/variables";

:root {
  /*--back-color: #004736;*/
  /*--back-color: #231f20;*/
  --back-color: #285238;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



body {
  background-color: var(--back-color);
  transition: 0.15s ;
}


.App-header {
  margin: auto;
  max-width: $max-content-width;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 1em;
  color: white;
}

.App-link {
  color: #61dafb;
}
