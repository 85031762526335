@import "../../utils/variables";


$day-w: 1.0rem;
$day-h: 1.0rem;




.yearContainer {
    max-width: min(calc($day-w * 34),70vw);
    height: inherit;
    margin: 0 auto;
    
}

.monthContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    justify-content: center;


}
 

.dayContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.day {
    width: $day-w;
    height: $day-h;
    color:white;
    font-size: 5px;
}

.color-scale-1 {
    background-color: rgba(40, 82, 56,0.0);
}

.color-scale-2 {
    background-color: rgba(40, 82, 56,0.33);
}

.color-scale-3 {
    background-color: rgba(40, 82, 56,0.66);
}

.color-scale-4 {
    background-color: rgba(40, 82, 56,1);
}

