@import "../../utils/variables";

.weeklyView .barChart {
  width: 100%;
  flex: 2 0 20vh;
  margin-right: 1rem;
  height: 20vh;
}

.weeklyView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
}

@media (min-width: $max-mobile-width) {
  .weeklyView {
    height: 40vh;
  }
}